<template>
  <b-card
    :title="title"
    class="fw-card"
  >
    <chartjs-component-line-chart
      ref="lineChart"
      :height="height"
      :data="data"
      :options="chartOptions"
    />
  </b-card>
</template>

<script>
import ChartjsComponentLineChart from '@/components/charts/charts-components/ChartjsComponentLineChart.vue'

export default {
  components: {
    ChartjsComponentLineChart,
  },
  props: {
    config: {
      type: Object,
      default: () => ({
        max: 50,
      }),
    },
    title: {
      type: String,
      default: '',
    },
    data: {
      type: Object,
      default: null,
    },
    height: {
      type: Number,
      default: 100,
    },
    extraOptions: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    options() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        backgroundColor: false,
        layout: {
          padding: {
            left: -30,
            right: 4,
          },
        },
        scales: {
          xAxes: [
            {
              display: false,
              scaleLabel: {
                display: true,
              },
              gridLines: {
                display: true,
                color: 'rgba(200, 200, 200, 0.2)',
                zeroLineColor: 'rgba(200, 200, 200, 0.2)',
              },
              ticks: {
                fontColor: '#6e6b7b',
              },
            },
          ],
          yAxes: [
            {
              display: true,
              scaleLabel: {
                display: true,
              },
              ticks: {
                stepSize: this.config.max / 5,
                min: -this.config.max,
                max: this.config.max,
                fontColor: '#6e6b7b',
                callback: function (value) {
                  return value + ' cm'
                },
              },
              gridLines: {
                display: true,
                color: 'rgba(200, 200, 200, 0.2)',
                zeroLineColor: 'rgba(200, 200, 200, 0.2)',
              },
            },
          ],
        },
        legend: {
          display: false,
        },
      }
    },
    chartOptions() {
      return { ...this.options, ...this.extraOptions }
    },
  },
  methods: {
    render() {
      this.$refs.lineChart.render()
    },
  },
}
</script>
