<template>
  <div class="rest-container">
    <b-container fluid>
      <b-row class="bg-light rounded mt-n2 mb-1 py-2 px-1 d-flex align-items-center">
        <b-col cols="3">
          <h2 class="h4 m-0">
            {{ $t('Docking') }} #{{ dockingId }} - {{ dockingVessels }}
          </h2>
        </b-col>
        <b-col
          v-show="dockingEnabled"
          cols="6"
        >
          <b-row>
            <b-col>
              <div class="d-flex align-items-center">
                <label class="mr-1">Docking&nbsp;Mode</label>
                <b-form-select
                  v-model="dockingState.docking.mode"
                  :options="modeOptions"
                  size="sm"
                  @change="updateDockingMode()"
                />
              </div>
            </b-col>
            <b-col>
              <div class="d-flex align-items-center">
                <label class="wl-label">{{ $t('Water Levels') }}</label>
                <b-form-checkbox
                  v-model="deflectionValues"
                  name="check-button"
                  switch
                >
                  {{ $t('Deflections') }}
                </b-form-checkbox>
              </div>
            </b-col>
            <b-col>
              <div class="d-flex align-items-center">
                <label class="wl-label">{{ $t('Feet') }}</label>
                <b-form-checkbox
                  v-model="meterValues"
                  name="check-button"
                  switch
                >
                  {{ $t('Meters') }}
                </b-form-checkbox>
              </div>
            </b-col>
            <b-col v-if="allowMisImport && dockingStarted">
              <ImportMIS
                endpoint="dockings/mis-import"
                :entity-id="dockingId"
              />
            </b-col>
          </b-row>
        </b-col>
        <b-col
          v-if="loaded && !dockingState.docking.endDate"
          cols="3"
          class="text-right"
        >
          <b-button
            v-show="soundPlaying"
            variant="secondary"
            size="md"
            class="mr-2"
            @click="stopSound"
          >
            stop sound
          </b-button>
          <b-button
            :variant="dockingStarted ? 'danger' : 'success'"
            :disabled="startStopDisabled"
            size="md"
            class="mr-2"
            @click="startStopDocking"
          >
            {{ $t((dockingStarted ? 'End' : 'Start') + ' Docking') }}
          </b-button>
        </b-col>
      </b-row>
    </b-container>
    <b-modal
      id="endConfirmationModal"
      :visible="confirmEndDocking"
      :title="$t('End Docking')"
      header-bg-variant="danger"
      size="lg"
      centered
      @change="confirmEndDocking = false"
    >
      <p>
        {{ $t('EndDockingMsg') }}
      </p>
      <template #modal-footer="{ close }">
        <b-button
          variant="secondary"
          @click="close"
        >
          {{ $t('Cancel') }}
        </b-button>
        <b-button
          variant="danger"
          data-dismiss="modal"
          @click="startStopDocking(), close()"
        >
          {{ $t('Yes') }}
        </b-button>
      </template>
    </b-modal>
    <b-alert
      variant="danger"
      dismissible
      fade
      :show="dockingState.totalLevelExceeded"
    >
      {{ $t('totalWaterLevelLow') }}
    </b-alert>
    <div v-show="dockingEnabled">
      <b-row fluid>
        <b-col md="8">
          <b-card
            :header="$t('Deflections')"
            :header-bg-variant="portMaxDeflectionAlert ? 'danger' : 'info'"
            class="charts-card charts-deflection"
            header-text-variant="white"
          >
            <line-chart
              v-if="loaded"
              v-show="dockingState.docking.mode != 'SB'"
              ref="portChart"
              :config="chartConfig"
              :data="portChartData"
              title="PORT"
            />
            <line-chart
              v-if="loaded"
              v-show="dockingState.docking.mode != 'PORT'"
              ref="starboardChart"
              :config="chartConfig"
              :data="starboardChartData"
              title="SB"
            />
          </b-card>
        </b-col>
        <b-col md="4">
          <b-card
            :header="$t('Angles')"
            :header-bg-variant="portMaxDeflectionAlert ? 'danger' : 'info'"
            class="charts-card charts-angles"
            header-text-variant="white"
          >
            <line-chart
              v-if="loaded"
              ref="pitchChart"
              :config="angleChartConfig"
              :data="pitchChartData"
              :extra-options="angleChartOptions"
              :class="'trim-chart' + getModeClass()"
              title="Trim"
            />
            <line-chart
              v-if="loaded"
              ref="rollChart"
              :config="angleChartConfig"
              :data="rollChartData"
              :extra-options="angleChartOptions"
              :class="'list-chart' + getModeClass()"
              title="List"
            />
          </b-card>
        </b-col>
      </b-row>
      <div class="bg-white mb-2 pl-5">
        <div class="pl-5">
          <div
            :style="imageStyle"
            class="docking-wrapper"
          >
            <div
              v-for="sensor in dockingState.sensors"
              :key="sensor.title"
            >
              <div :class="`spot spot${sensor.title}${getSide(sensor.title)}${getModeClass()}`">
                <b-badge :class="'badge' + getSide(sensor.title)">
                  {{ getSensorValue(sensor) }}
                </b-badge>
                <b-button
                  :id="`btn-${sensor.id}`"
                  :variant="getSensorClass(sensor)"
                  :class="'btn-radius circle ' + getSensorClass(sensor)"
                >
                  {{ sensor.title }}
                </b-button>
                <b-popover
                  :target="`btn-${sensor.id}`"
                  variant="info"
                  triggers="hover click"
                  placement="bottom"
                  custom-class="msmnts-tip"
                >
                  <template #title>
                    {{ $t('Last measurements') }}
                  </template>
                  <b-table
                    v-if="sensor.lastMeasurements.length"
                    striped
                    hover
                    :items="sensor.lastMeasurements"
                    :fields="[
                      {
                        label: $t('time'),
                        key: 'samplingTime',
                      },
                      {
                        label: $t('Value'),
                        key: 'mvalue',
                        class: 'last-msmnt',
                      },
                    ]"
                  >
                    <template #cell(mvalue)="data">
                      {{ getMsmntValue(data.item) }}
                    </template>
                  </b-table>
                  <p
                    v-else
                    class="text-center pt-1 font-weight-bolder"
                  >
                    {{ $t('No Measurements') }}
                  </p>
                </b-popover>
              </div>
            </div>
            <div class="info-wrapper deflection-wrapper port">
              <b-card
                :header="$t(portSagHogLabel)"
                :header-bg-variant="portMaxDeflectionAlert ? 'danger' : 'info'"
                :class="'text-center port-deflection' + getModeClass()"
                header-text-variant="white"
              >
                <b-card-text>
                  {{ dockingState.portDeflection.toFixed(1) }} cm
                </b-card-text>
              </b-card>
              <b-row class="justify-content-md-center text-center">
                <b-col>
                  <b-card
                    :header-bg-variant="maxTrimAlert ? 'danger' : 'info'"
                    header="Trim"
                    header-text-variant="white"
                  >
                    <b-card-text>
                      {{ dockingState.trimAngle.toFixed(2) }} °
                    </b-card-text>
                  </b-card>
                </b-col>
                <b-col>
                  <b-card
                    :header-bg-variant="maxListAlert ? 'danger' : 'info'"
                    :class="'list-angle' + getModeClass()"
                    header="List"
                    header-text-variant="white"
                  >
                    <b-card-text>
                      {{ dockingState.listAngle.toFixed(2) }} °
                    </b-card-text>
                  </b-card>
                </b-col>
              </b-row>
              <b-card
                :header="$t(sbSagHogLabel)"
                :header-bg-variant="sbMaxDeflectionAlert ? 'danger' : 'info'"
                :class="'text-center sb-deflection' + getModeClass()"
                header-text-variant="white"
                class="text-center"
              >
                <b-card-text>
                  {{ dockingState.sbDeflection.toFixed(1) }} cm
                </b-card-text>
              </b-card>
              <b-row class="justify-content-md-center text-center">
                <b-col md="12">
                  <b-card
                    header-bg-variant="secondary"
                    header="Max Deflection"
                    header-text-variant="white"
                  >
                    <b-card-text>
                      {{ dockingState.maxDeflectionValue.toFixed(1) }} cm
                    </b-card-text>
                  </b-card>
                </b-col>
                <b-col>
                  <b-card
                    header-bg-variant="secondary"
                    header="Max Trim Angle"
                    header-text-variant="white"
                  >
                    <b-card-text>
                      {{ dockingState.maxTrimValue.toFixed(2) }} °
                    </b-card-text>
                  </b-card>
                </b-col>
                <b-col>
                  <b-card
                    header-bg-variant="secondary"
                    header="Max List Angle"
                    header-text-variant="white"
                  >
                    <b-card-text>
                      {{ dockingState.maxListValue.toFixed(2) }} °
                    </b-card-text>
                  </b-card>
                </b-col>
              </b-row>
            </div>
          </div>
        </div>
      </div>
      <b-card-actions
        :title="$t('Warnings')"
        action-collapse
      >
        <b-alert
          variant="warning"
          dismissible
          fade
          :show="dockingState.bothSidesInactive"
        >
          {{ $t('bothSidesInactiveDesc') }}
        </b-alert>
        <b-alert
          variant="warning"
          dismissible
          fade
          :show="maxTrimAlert"
        >
          {{ $t('maxTrimAlertDesc') }}
        </b-alert>
        <b-alert
          variant="warning"
          dismissible
          fade
          :show="maxListAlert"
        >
          {{ $t('maxListAlertDesc') }}
        </b-alert>
        <b-alert
          variant="warning"
          dismissible
          fade
          :show="portMaxDeflectionAlert"
        >
          {{ $t('portMaxDeflectionAlertDesc') }}
        </b-alert>
        <b-alert
          variant="warning"
          dismissible
          fade
          :show="sbMaxDeflectionAlert"
        >
          {{ $t('sbMaxDeflectionAlertDesc') }}
        </b-alert>
        <b-alert
          v-for="sensor in dockingState.sensors"
          :key="sensor.title"
          variant="danger"
          dismissible
          fade
          :show="!sensor.active || sensor.positionZ < dockingState.nodeLevelThreshold"
        >
          {{
            $t(
              isWaterLevelLow(sensor)
                ? 'sensorWaterLevelLow'
                : 'InactiveSensor',
              {
                i: sensor.title,
              },
            )
          }}
        </b-alert>
      </b-card-actions>
    </div>
  </div>
</template>

<script>
import useAppConfig from '@core/app-config/useAppConfig'
import LineChart from '@/components/charts/charts-components/LineChart.vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import ImportMIS from '@/layouts/entity/ImportMIS.vue'
import alarmSiren from '@/assets/sounds/alarm-siren.mp3'
import electronicError from '@/assets/sounds/electronic-cash-register-error-beep.mp3'
import intruderAlert from '@/assets/sounds/intruder-alert-siren.mp3'
import axios from '@/libs/axios'

export default {
  components: {
    LineChart,
    BCardActions,
    ImportMIS,
  },
  setup() {
    const { skin } = useAppConfig()
    return { skin }
  },
  data() {
    return {
      loaded: false,
      confirmEndDocking: false,
      deflectionValues: true,
      meterValues: true,
      dockingState: {
        docking: {
          mode: null,
        },
        totalLevelExceeded: false,
        bothSidesInactive: false,
        portDeflection: 0,
        sbDeflection: 0,
        trimAngle: 0,
        listAngle: 0,
        sensors: [],
        startDate: null,
        refreshInterval: 30,
        maxDeflectionValue: 50,
        alertDeflectionValue: 30,
        nodeLevelThreshold: 9999,
        maxTrimValue: 9999,
        maxListValue: 9999,
      },
      chartConfig: {
        max: 50,
      },
      angleChartConfig: {
        max: 5,
      },
      modeOptions: [
        { value: 'BOTH', text: 'Both Sides' },
        { value: 'PORT', text: 'Port' },
        { value: 'SB', text: 'StarBoard' },
      ],
      portChartData: {},
      starboardChartData: {},
      pitchChartData: {},
      rollChartData: {},
      startStopDisabled: false,
      dockingStateTimeout: null,
      currentAudio: null,
      soundPlaying: false,
    }
  },
  computed: {
    dockingId() {
      return parseInt(this.$route.params.id)
    },
    dockingVessels() {
      if (this.dockingState.docking.vessels) {
        return this.dockingState.docking.vessels.map(v => v.title).join(', ')
      }
      return ''
    },
    dockingStarted() {
      return !!this.dockingState.docking.startDate
    },
    dockingEnabled() {
      return this.dockingStarted && !this.dockingState.totalLevelExceeded
    },
    allowMisImport() {
      return !!process.env.VUE_APP_MIS_IMPORT
    },
    imageStyle() {
      return (
        'background: url(' +
        require('@/assets/images/pages/platform-big-2.png') +
        ') scroll no-repeat #fff center / contain'
      )
    },
    portSagHogLabel() {
      return this.sagHogLabel(this.dockingState.portDeflection)
    },
    sbSagHogLabel() {
      return this.sagHogLabel(this.dockingState.sbDeflection)
    },
    maxTrimAlert() {
      return Math.abs(this.dockingState.trimAngle) > this.dockingState.maxTrimValue
    },
    maxListAlert() {
      return Math.abs(this.dockingState.listAngle) > this.dockingState.maxListValue
    },
    portMaxDeflectionAlert() {
      return Math.abs(this.dockingState.portDeflection) >
        this.dockingState.alertDeflectionValue
    },
    sbMaxDeflectionAlert() {
      return Math.abs(this.dockingState.sbDeflection) >
        this.dockingState.alertDeflectionValue
    },
    portDangerDeflectionAlert() {
      return Math.abs(this.dockingState.portDeflection) >
        this.dockingState.maxDeflectionValue
    },
    sbDangerDeflectionAlert() {
      return Math.abs(this.dockingState.sbDeflection) >
        this.dockingState.maxDeflectionValue
    },
    angleChartOptions() {
      return {
        layout: {
          padding: {
            left: 0,
            right: 0,
          },
        },
        scales: {
          xAxes: [
            {
              display: true,
            },
          ],
        },
      }
    },
    isDark() {
      return this.skin === 'dark'
    }
  },
  mounted() {
    this.fetchDockingState()
  },
  beforeDestroy() {
    clearTimeout(this.dockingStateTimeout)
  },
  methods: {
    sagHogLabel(deflection) {
      if (deflection > 0) {
        return 'hogging'
      }
      if (deflection < 0) {
        return 'sagging'
      }
      return 'No_Hogging_Sagging'
    },
    async fetchDockingState() {
      const response = await axios.get('dockings/' + this.dockingId, {
        params: { mode: this.dockingState.docking.mode },
      })
      this.updateDockingState(response.data)
      this.dockingStateTimeout = setTimeout(() => {
        this.fetchDockingState()
      }, this.dockingState.refreshInterval * 1000)
    },
    async updateDockingMode() {
      const response = await axios.put('dockings/mode', {
        id: this.dockingId,
        mode: this.dockingState.docking.mode,
      })
      this.updateDockingState(response.data)
    },
    async startStopDocking() {
      this.startStopDisabled = true
      if (this.dockingStarted && !this.confirmEndDocking) {
        this.confirmEndDocking = true
      } else {
        const response = await axios.put('dockings/start-stop', {
          id: this.dockingId,
          enabled: !this.dockingStarted,
        })
        this.updateDockingState(response.data)
      }
      this.startStopDisabled = false
    },
    updateDockingState(data) {
      this.stopSound()
      this.dockingState = data
      this.portChartData = this.sideChartData('P')
      this.starboardChartData = this.sideChartData('S')
      this.pitchChartData = this.getPitchChartData()
      this.rollChartData = this.getRollChartData()
      this.chartConfig = {
        max: this.dockingState.maxDeflectionValue,
      }
      if (this.dockingState.totalLevelExceeded || this.dockingState.bothSidesInactive || this.portDangerDeflectionAlert || this.sbDangerDeflectionAlert) {
        this.playErrorSound()
      } else if (this.dockingState.sensors.findIndex(sensor => !sensor.active || sensor.positionZ < this.dockingState.nodeLevelThreshold) > -1) {
        this.playDangerSound()
      } else if (this.maxTrimAlert || this.maxListAlert || this.portMaxDeflectionAlert || this.sbMaxDeflectionAlert) {
        this.playAlertSound()
      }
      if (this.loaded) {
        this.$nextTick(() => {
          this.$refs.portChart.render()
          this.$refs.starboardChart.render()
          this.$refs.pitchChart.render()
          this.$refs.rollChart.render()
        })
      } else {
        this.loaded = true
      }
    },
    onHover() {
      this.$refs.tooltip.$emit('open')
    },
    checkNonZero(value) {
      return value || value === 0
    },
    getSide(sensorTitle) {
      return sensorTitle.includes('P') ? ' port' : ' starboard'
    },
    getModeClass() {
      const mode = this.dockingState.docking.mode
        ? this.dockingState.docking.mode.toLowerCase()
        : 'both'
      return ' mode-' + mode
    },
    isWaterLevelLow(sensor) {
      const activeSensor = this.dockingState.sensors.find(s => s.active)
      if (activeSensor) {
        return sensor.lastParsing === activeSensor.lastParsing
      }
      return false
    },
    getSensorValue(sensor) {
      if (this.deflectionValues) {
        if (
          !(
            sensor.active &&
            sensor.positionZ >= this.dockingState.nodeLevelThreshold
          )
        ) {
          return '-'
        }
        return this.checkNonZero(sensor.retwist)
          ? this.getMsmntMetric(sensor.retwist, 1, 'cm')
          : '-'
      }
      return sensor.positionZ
        ? this.getMsmntMetric(sensor.positionZ, 2, 'm')
        : '-'
    },
    getMsmntValue(msmnt) {
      if (!msmnt.mvalue) {
        return $t('Error') + ': ' + msmnt.errorCode
      }
      if (this.deflectionValues && this.checkNonZero(msmnt.retwist)) {
        return this.getMsmntMetric(msmnt.retwist, 1, 'cm')
      }
      return this.getMsmntMetric(msmnt.mvalue, 2, 'm')
    },
    getMsmntMetric(value, decPlaces, unit) {
      if (!this.meterValues) {
        if (unit === 'cm') {
          unit = 'inches'
          value /= 2.54
        } else {
          unit = 'feet'
          value *= 3.28084
        }
      }
      return value.toFixed(decPlaces) + ' ' + unit
    },
    getSensorClass(sensor) {
      if (
        !sensor.active ||
        sensor.positionZ < this.dockingState.nodeLevelThreshold
      ) {
        return 'danger'
      }
      return 'success'
    },
    sideChartData(side) {
      const portSensors = this.dockingState.sensors
        .filter(s => s.active && s.title.includes(side))
        .reverse()
      const color = side === 'P' ? '#f00f00' : '#008000'
      const alertVal = this.dockingState.alertDeflectionValue
      return {
        labels: portSensors.map(s => s.title),
        datasets: [
          {
            data: portSensors.map(s =>
              s.retwist != null && !isNaN(s.retwist)
                ? s.retwist.toFixed(1)
                : '-',
            ),
            borderColor: color,
            backgroundColor: color,
            fill: false,
          },
          {
            data: portSensors.map(s => alertVal),
            borderWidth: 1,
            borderColor: '#FFA500',
            backgroundColor: '#FFA500',
            fill: false,
            pointRadius: 0,
          },
          {
            data: portSensors.map(s => -alertVal),
            borderWidth: 1,
            borderColor: '#FFA500',
            backgroundColor: '#FFA500',
            fill: false,
            pointRadius: 0,
          },
        ],
      }
    },
    getPitchChartData() {
      return this.angleChartData(this.dockingState.trimAngle.toFixed(2), ['BOW', 'STERN'])
    },
    getRollChartData() {
      return this.angleChartData(this.dockingState.listAngle.toFixed(2), ['SB', 'PORT'])
    },
    angleChartData(val, labels) {
      const color = this.isDark ? '#d0d2d6' : '#333'
      return {
        labels,
        datasets: [
          {
            data: [0, val],
            borderColor: color,
            backgroundColor: color,
            fill: false,
          },
        ],
      }
    },
    playAlertSound() {
      this.playSound(intruderAlert)
    },
    playDangerSound() {
      this.playSound(electronicError)
    },
    playErrorSound() {
      this.playSound(alarmSiren)
    },
    async playSound(sound) {
      const audioCtx = new window.AudioContext()
      this.currentAudio = audioCtx.createBufferSource()
      const arrayBuffer = await fetch(sound).then((res) => res.arrayBuffer())
      const audioBuffer = await audioCtx.decodeAudioData(arrayBuffer)
      this.currentAudio.buffer = audioBuffer
      this.currentAudio.loop = true
      this.currentAudio.connect(audioCtx.destination)
      this.currentAudio.start()
      this.soundPlaying = true
    },
    stopSound() {
      if (this.currentAudio) {
        this.currentAudio.stop()
        this.soundPlaying = false
      }
    }
  },
}
</script>

<style>
.wl-label {
  margin: -8px 7px 0 0 !important;
}

.docking-wrapper {
  position: relative;
  width: 1200px;
  height: 400px;
  margin: 0 auto;
}

hr.border-red {
  border: 2px solid red;
  background: red;
}

hr.border-green {
  border: 2px solid green;
  background: green;
}

.circle {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  transform: translate(-50%, -50%);
}

.circle:before,
.circle:after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border: 1px solid #089900;
  border-radius: 50%;
}

.circle:before {
  animation: ripple 2s linear infinite;
}

.circle:after {
  animation: ripple 2s linear 1s infinite;
}

.circle.success {
  background-color: #00ff00;
}

.circle.success:before,
.circle.success:after {
  border: 1px solid #089900;
}

.circle.danger {
  background-color: red;
}

.circle.danger:before,
.circle.danger:after {
  border: 1px solid red;
}

.fw-card>.card-body {
  padding: 0 0 0;
}

.fw-card:first-child>.card-body {
  padding-top: 1.5em;
}

.card.fw-card {
  margin-bottom: 1.5em;
}

.card.fw-card:last-child {
  margin-bottom: 0.5em;
}

.card.fw-card .card-title {
  margin-bottom: 0.5em;
}

.card.charts-card .card-header {
  padding: 0.8rem 1.5rem;
}

@keyframes ripple {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.3);
    opacity: 1;
  }

  100% {
    transform: scale(1.6);
    opacity: 0;
  }
}

.btn-radius {
  border-radius: 50%;
  padding: 9px 12px;
}

.spot {
  width: 100px;
  height: 100px;
  background-repeat: no-repeat;
  z-index: 1;
  position: absolute;
}

.spot.starboard.mode-port,
.spot.port.mode-sb,
.sb-deflection.mode-port,
.port-deflection.mode-sb,
.list-angle.mode-port,
.list-angle.mode-sb {
  visibility: hidden;
}

.list-chart.mode-port,
.list-chart.mode-sb {
  display: none;
}

.spotP1 {
  left: 1060px;
}

.spotP2 {
  left: 870px;
}

.spotP3 {
  left: 670px;
}

.spotP4 {
  left: 500px;
}

.spotP5 {
  left: 310px;
}

.spotP6 {
  left: 170px;
}

.spotS1 {
  left: 1060px;
}

.spotS2 {
  left: 870px;
}

.spotS3 {
  left: 670px;
}

.spotS4 {
  left: 500px;
}

.spotS5 {
  left: 310px;
}

.spotS6 {
  left: 170px;
}

.docking-wrapper .badge {
  position: absolute;
  width: 72px;
  left: -36px;
}

.badge.port {
  top: -56px;
}

.badge.starboard {
  top: 35px;
}

.raw-badge.badge.port {
  top: -80px;
}

.raw-badge.badge.starboard {
  top: 59px;
}

.spot.port {
  top: 85px;
}

.spot.starboard {
  top: 300px;
}

.deflection-wrapper {
  left: -174px;
  width: 180px;
  font-size: 0.8em;
}

.deflection-wrapper.port {
  top: 12px;
}

.deflection-wrapper.starboard {
  top: auto;
  bottom: 12px;
}

.badge-wrapper {
  top: 150px;
  left: calc(50% - 170px);
  width: 300px;
}

.info-wrapper {
  position: absolute;
}

.info-wrapper .card {
  margin-bottom: 0.8rem;
}

.info-wrapper .card .card-header {
  justify-content: center;
  padding: 0.6rem 0.5rem;
  line-height: 1em;
}

.info-wrapper .card-body {
  padding: 0.5rem 0.4rem;
}

.info-wrapper p.card-text {
  padding-top: 0.5rem;
  font-weight: 700;
}

.blink {
  animation: blinker 1s linear infinite;
}

.msmnts-tip .popover-body {
  padding: 0;
  font-size: 0.8em;
}

td.last-msmnt {
  font-size: 1.1em;
  font-weight: 700;
}

.wl-label~.custom-switch .custom-control-label::before {
  background-color: #00cfe8;
}

.wl-label~.custom-switch .custom-control-input:checked~.custom-control-label::before {
  background-color: #f7505a;
}

@keyframes blinker {
  50% {
    opacity: 1;
    background-color: orange;
  }
}
</style>
