<template>
  <div>
    <b-button
      v-b-modal.import-mis
      variant="info"
      size="sm"
    >
      Import MIS
    </b-button>

    <b-modal
      id="import-mis"
      ref="modal"
      title="Upload MIS"
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk"
    >
      <form
        ref="form"
        @submit.stop.prevent="handleSubmit"
      >
        <b-form-group
          label="File"
          label-for="file-input"
          invalid-feedback="File is required"
          :state="fileState"
        >
          <b-form-file
            id="file-input"
            v-model="file"
            :state="fileState"
            required
            accept=".mis"
          />
        </b-form-group>
      </form>

      <div
        v-if="loading"
        class="d-flex align-items-center justify-content-center mt-2 mb-2"
      >
        <b-spinner class="mr-1" />
        <span class="font-weight-bold">Uploading...</span>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton, BModal, BFormGroup, BFormFile, BSpinner,
} from 'bootstrap-vue'
import axios from '@/libs/axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BButton,
    BModal,
    BFormGroup,
    BFormFile,
    BSpinner,
  },
  props: {
    entityId: {
      type: Number,
      required: true,
    },
    endpoint: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      file: null,
      fileState: null,
      loading: false,
    }
  },
  methods: {
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity()
      this.fileState = valid
      return valid
    },

    resetModal() {
      this.file = null
      this.fileState = null
      this.loading = false
    },

    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmit()
    },

    handleSubmit() {
      if (!this.checkFormValidity()) {
        return
      }

      this.loading = true

      const formData = new FormData()
      formData.append('file', this.file)

      axios.post(`${this.endpoint}/${this.entityId}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then(res => {
          this.$bvModal.hide('import-mis')

          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.$t('MIS Uploaded'),
              icon: 'CheckIcon',
              variant: 'success',
              text: res.message,
            },
          })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.$t('Save Failure'),
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: error.message,
            },
          })
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>
